import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";
import { Box } from "@material-ui/core";

const WarningDialog = ({ open, onClose, text }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <WarningIcon color="error" style={{ marginRight: 8 }} />
                    <Typography variant="h6">ATENCIÓN</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" style={{ marginBottom: 17 }}>{ text }</Typography>
            </DialogContent>
        </Dialog>
    );
};

export default WarningDialog;
